<template>
  <section class="bg_color">
    <h2>FAQ</h2>
    <section class="inner p0">
      <div class="box_lst mt0 slide moter_lst pb0">
        <h4 class="ttl_lst" id="info">
          <div class="inner tac no_icon">
            <span class="moter_icon">基本情報</span>
          </div>
        </h4>
        <div class="box_txt-list faq_list">
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1001')">
              <span class="faq_num">Q1.</span>MOGってなに？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1001') >= 0">
                Motor Owner GrandPrix の略です。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1001')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1001') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1002')">
              <span class="faq_num">Q2.</span>モグーってなに？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1002') >= 0">
                このゲームで獲得できるポイントの単位です。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1002')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1002') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1003')">
              <span class="faq_num">Q3.</span>モーターってなに？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1003') >= 0">
                ボートに装着されているエンジンをモーターと呼びます。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1003')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1003') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <!-- <div class="faq_box">
            <p class="faq_q" @click="toggle('1004')">
              <span class="faq_num">Q4.</span>モーターは何回使われるの？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1004') >= 0">
                ボートについているエンジンをモーターと呼びます。何回も使われます。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1004')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1004') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div> -->
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1004')">
              <span class="faq_num">Q4.</span>MOGの参加条件はありますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1004') >= 0">
                ・２０歳以上<br />・日本国内にお住まいの方（国籍は問いません）<br />・営業性・事業性個人、法人の方はご登録いただけません。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1004')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1004') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1005')">
              <span class="faq_num">Q5.</span>蒲郡以外のレースは対象ですか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1005') >= 0">
                対象ではありません。ボートレース蒲郡で開催のレースのみが対象です。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1005')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1005') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1006')">
              <span class="faq_num">Q6.</span
              >ボートレース蒲郡の開催期間を教えてください。
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1006') >= 0">
                下記よりご確認ください。<br />
                <a
                  href="https://www.gamagori-kyotei.com/01cal/01cal.htm"
                  target="_blank"
                  >https://www.gamagori-kyotei.com/01cal/01cal.htm</a
                >
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1006')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1006') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1007')">
              <span class="faq_num">Q7.</span
              >既に蒲郡ムーンライトポイントクラブに登録しているのですが、蒲郡ムーンライトポイントクラブとは違いますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1007') >= 0">
                蒲郡ムーンライトポイントクラブとは異なるサービスのため、別途、登録をお願いします。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1007')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1007') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box_lst mt0 slide moter_lst pb0">
        <h4 class="ttl_lst" id="join">
          <div class="inner tac no_icon">
            <span class="moter_icon">参加方法</span>
          </div>
        </h4>
        <div class="box_txt-list faq_list">
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1106')">
              <span class="faq_num">Q1.</span>会員登録の方法を教えてください。
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1106') >= 0">
                当サイトのMENU欄「新規登録」から登録を進めてください。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1106')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1106') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1100')">
              <span class="faq_num">Q2.</span
              >電話や本場からは参加できないのですか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1100') >= 0">
                インターネット専用です。電話や本場では参加出来ません。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1100')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1100') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1101')">
              <span class="faq_num">Q3.</span>参加には料金がかかりますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1101') >= 0">
                料金は無料です。ただし当サイトを利用する際の通信費はお客様のご負担となります。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1101')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1101') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1102')">
              <span class="faq_num">Q4.</span
              >一度登録を抹消し、あらためて登録し直したいのですが。（リセットしたい）
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1102') >= 0">
                再登録は可能ですが、これまでの獲得モグーは失われるのでご注意ください。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1102')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1102') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1103')">
              <span class="faq_num">Q5.</span>退会したいです。
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1103') >= 0">
                マイページ - 基本情報より退会ください。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1103')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1103') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1105')">
              <span class="faq_num">Q6.</span
              >モーターを間違えて登録してしまいました。
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1105') >= 0">
                一度選んだモーターは変更できません。最大６つまで追加可能です。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1105')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1105') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box_lst mt0 slide moter_lst pb0">
        <h4 class="ttl_lst" id="prize">
          <div class="inner tac no_icon">
            <span class="moter_icon">賞品</span>
          </div>
        </h4>
        <div class="box_txt-list faq_list">
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1200')">
              <span class="faq_num">Q1.</span>上位入賞者は何がもらえますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1200') >= 0">
                オーナーランキング上位100名様に、<br
                  class="hidden-md"
                />総額77万ガマポプレゼントします。<br />配分は、以下になります。<br />
                1位:150,000pt&ensp;2位:100,000pt<br />
                3位:70,000pt&ensp;4位:50,000pt&ensp;5位:30,000pt<br />
                6-10位:10,000pt&ensp;11-20位:7,000pt<br />
                21-50位:5,000pt&ensp;51-100位:2,000pt
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1200')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1200') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1206')">
              <span class="faq_num">Q2.</span>参加賞はありますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1206') >= 0">
                100位以降で下一桁が「7」の順位（例：107位、567位、777位）のMOGユーザーへ、<br />
                BR蒲郡オリジナルQUOカード（500円分）をプレゼントします。<br />
                ※1497位で打ち切りとなります。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1206')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1206') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1201')">
              <span class="faq_num">Q3.</span>VS GAMAファンの賞品はなんですか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1201') >= 0">
                選んだ対戦相手に勝利した人の中から抽選で5名様に、対戦相手のサイン入りQUOカード￥500分をプレゼントします。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1201')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1201') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1207')">
              <span class="faq_num">Q4.</span>賞品はどこに送られてきますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1207') >= 0">
                当選者様に、ご登録いただいているメールアドレスへ当選者用登録フォームをお送り致します。そちらに入力いただいた住所に送付いたします。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1207')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1207') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1202')">
              <span class="faq_num">Q5.</span>賞品はいつもらえるの？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1202') >= 0">
                現モーターの使用期間終了後、随時賞品の発送を行います。しばらくお待ちください。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1202')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1202') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <!-- <div class="faq_box">
            <p class="faq_q" @click="toggle('1203')">
              <span class="faq_num">Q4.</span>賞品が届かない
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1203') >= 0">
                ２０２３年５月の最終レース終了後
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1203')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1203') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div> -->
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1204')">
              <span class="faq_num">Q6.</span>モグーは換金できますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1204') >= 0">
                モグーは換金出来ません。その他ポイントや舟券にも交換できません。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1204')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1204') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1205')">
              <span class="faq_num">Q7.</span
              >MOGの年間結果発表はいつどこで行われますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1205') >= 0">
                現モーターの使用期間終了後、過去ランキングページにて年間結果発表を行います。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1205')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1205') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box_lst mt0 slide moter_lst pb0">
        <h4 class="ttl_lst" id="rule">
          <div class="inner tac no_icon">
            <span class="moter_icon">ルール</span>
          </div>
        </h4>
        <div class="box_txt-list faq_list">
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1300')">
              <span class="faq_num">Q1.</span
              >選んだモーターは変えられないのですか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1300') >= 0">
                一度登録したモーターは変更することができません。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1300')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1300') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1301')">
              <span class="faq_num">Q2.</span
              >選んだモーターのモグーはいつから反映されるますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1301') >= 0">
                レース終了後速やかにマイページに反映されます。レース結果はボートレース振興会のWEBサイトに準じます。通信の都合により遅れる場合もあります。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1301')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1301') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1302')">
              <span class="faq_num">Q3.</span
              >モーターのモグーに完走手当は含まれますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1302') >= 0">
                含まれません。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1302')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1302') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1303')">
              <span class="faq_num">Q4.</span
              >優勝戦が不成立（中止）の場合獲得モグーはどうなりますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1303') >= 0">
                モグーでは加算されません。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1303')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1303') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1304')">
              <span class="faq_num">Q5.</span
              >モグーが同額だった場合の順位はどうなっていますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1304') >= 0">
                同順位となります。表示はユーザー登録順に表示されます。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1304')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1304') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1305')">
              <span class="faq_num">Q6.</span
              >期限までにモーターを選びきれなかったらどうなりますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1305') >= 0">
                モーター登録締切後はモーターを選ぶことはできません。2024/25seasonのモーター登録締切は2024年10月25日（金）となります。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1305')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1305') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1306')">
              <span class="faq_num">Q7.</span
              >モーターはいつまで選ぶことはできますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1306') >= 0">
                2024/25seasonのモーター登録期間は2024年7月16日(火)から2024年10月25日(金)までとなります。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1306')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1306') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1307')">
              <span class="faq_num">Q8.</span
              >各モーターの使用回数について教えてください。
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1307') >= 0">
                シーズンを通じて各モーターの使用回数には差があります。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1307')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1307') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1308')">
              <span class="faq_num">Q9.</span
              >モーターはどのように選べますか？（同時に6機登録しないといけませんか？）
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1308') >= 0">
                参加登録期間内（2024年7月16日（火）～10月25日（金）23時59分）であれば、複数回に分けてモーターを登録することができます。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1308')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1308') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1309')">
              <span class="faq_num">Q10.</span
              >登録したモーターのモグーはいつから加算されますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1309') >= 0">
                ご登録いただくと即時加算されていきます。ただし、登録モーターが出走するレースの発売締切後に登録された場合は、次の出走レースからの加算となります。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1309')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1309') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box_lst mt0 slide moter_lst pb0">
        <h4 class="ttl_lst" id="other">
          <div class="inner tac no_icon">
            <span class="moter_icon">その他</span>
          </div>
        </h4>
        <div class="box_txt-list faq_list">
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1400')">
              <span class="faq_num">Q1.</span
              >文字が文字化けしたり、サイトが壊れて表示されるのですが。
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1400') >= 0">
                MOGの対象ブラウザは以下になります。<br />
                以下バージョンのブラウザにてご確認ください。<br />
                ＜PC＞<br />
                Microsoft Edge （最新版）<br />
                Mozilla Firefox （最新版）<br />
                Google Chrome （最新版）<br />
                Safari （最新版）<br />
                ＜スマートフォン推奨OS＞<br />
                Android 11.X.X<br />
                Android 10.X.X<br />
                Android 9.X.X<br />
                iOS 15.X.X<br />
                iOS 14.X.X"<br />
                また対象ブラウザで利用しているのにも関わらず表示がおかしい場合は、ログアウトまたはキャッシュのクリアをしてください。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1400')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1400') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1401')">
              <span class="faq_num">Q2.</span
              >メールアドレスとパスワードを入力しても「ログインに失敗しました」と表示されます。
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1401') >= 0">
                全角半角スペースなど改めてお間違えないかご確認ください。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1401')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1401') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1402')">
              <span class="faq_num">Q3.</span
              >１つのメールアドレスで２人参加登録できますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1402') >= 0">
                できません。1アカウントにつき1メールアドレスの登録となります。2人分のメールアドレスをご用意ください。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1402')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1402') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1403')">
              <span class="faq_num">Q4.</span>どちらに問い合わせすればよいの？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1403') >= 0">
                問い合わせフォームよりお問い合わせください。お問い合わせフォームは<router-link
                  :to="{ name: 'Inquiry' }"
                  class="txt-link"
                  v-on:click="this.$clearAllIntervals()"
                  >コチラ</router-link
                >
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1403')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1403') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1404')">
              <span class="faq_num">Q5.</span
              >ＰＣから家族で参加します。<br />メールアドレスはそれぞれで持っていますが、１台のＰＣからみんなで参加できますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1404') >= 0">
                できます。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1404')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1404') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1405')">
              <span class="faq_num">Q6.</span>スマートフォン版の推奨環境について
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1405') >= 0">
                スマートフォン推奨OS<br />
                Android 11.X.X<br />
                Android 10.X.X<br />
                Android 9.X.X<br />
                iOS 15.X.X<br />
                iOS 14.X.X
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1405')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1405') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1406')">
              <span class="faq_num">Q7.</span>PC版の推奨環境について
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1406') >= 0">
                PC<br />
                Microsoft Edge （最新版）<br />
                Mozilla Firefox （最新版）<br />
                Google Chrome （最新版）<br />
                Safari （最新版）
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1406')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1406') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1407')">
              <span class="faq_num">Q8.</span
              >携帯電話（ガラケー/フィーチャーフォン）よりアクセスできないのですが
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1407') >= 0">
                セキュリティ上の観点から、当サイトは携帯電話（ガラケー/フィーチャーフォン）からはアクセスできません。悪しからずご了承ください。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1407')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1407') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1408')">
              <span class="faq_num">Q9.</span>登録完了メールが届かないのですが。
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1408') >= 0">
                ユーザー登録が完了している可能性があります。<br />
                ログインしてご確認ください。<br />
                完了していない場合は、再度ご登録ください。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1408')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1408') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1409')">
              <span class="faq_num">Q10.</span
              >ニックネームを変えたいがどうしたら良い？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1409') >= 0">
                変更はできません。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1409')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1409') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1410')">
              <span class="faq_num">Q11.</span>モグーで舟券は買えますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1410') >= 0">
                買えません。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1410')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1410') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1411')">
              <span class="faq_num">Q12.</span
              >登録の途中で通信が切れてしまいました。
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1411') >= 0">
                登録ができているかログインが可能かご確認ください。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1411')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1411') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
          <div class="faq_box">
            <p class="faq_q" @click="toggle('1412')">
              <span class="faq_num">Q13.</span
              >一年の半分を海外で生活しています。MOGに参加できますか？
            </p>
            <transition name="fade">
              <p class="faq_a" v-show="show_contents.indexOf('1412') >= 0">
                賞品が受け取れる日本国内の固定の住所があれば問題ありません。
              </p>
            </transition>
            <div class="faq_button" @click="toggle('1412')">
              <div class="faq_button_inner">
                <span
                  class="faq_line"
                  v-bind:class="[
                    show_contents.indexOf('1412') >= 0 ? 'active' : '',
                  ]"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ul class="side-menu" id="side-menu">
      <li class="side-menu__item">
        <a href="#info" class="side-menu__link"
          ><p class="side-menu__icon">
            <img src="@/assets/images/faq/ico_info.svg" alt="基本情報" /></p
        ></a>
      </li>
      <li class="side-menu__item">
        <a href="#join" class="side-menu__link"
          ><p class="side-menu__icon ico02">
            <img src="@/assets/images/faq/ico_join.svg" alt="参加方法" /></p
        ></a>
      </li>
      <li class="side-menu__item">
        <a href="#prize" class="side-menu__link"
          ><p class="side-menu__icon ico03">
            <img src="@/assets/images/faq/ico_prize.svg" alt="賞品" /></p
        ></a>
      </li>
      <li class="side-menu__item">
        <a href="#rule" class="side-menu__link"
          ><p class="side-menu__icon ico04">
            <img src="@/assets/images/faq/ico_rule.svg" alt="ルール" /></p
        ></a>
      </li>
      <li class="side-menu__item">
        <a href="#other" class="side-menu__link"
          ><p class="side-menu__icon ico05">
            <img src="@/assets/images/faq/ico_other.svg" alt="その他" /></p
        ></a>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "Faq",
  data() {
    return {
      show_contents: [
        "1001",
        "1002",
        "1003",
        "1004",
        "1005",
        "1006",
        "1007",
        "1008",
        "1100",
        "1101",
        "1102",
        "1103",
        "1104",
        "1105",
        "1106",
        "1200",
        "1201",
        "1202",
        "1203",
        "1204",
        "1205",
        "1206",
        "1207",
        "1300",
        "1301",
        "1302",
        "1303",
        "1304",
        "1305",
        "1306",
        "1307",
        "1308",
        "1309",
        "1400",
        "1401",
        "1402",
        "1403",
        "1404",
        "1405",
        "1406",
        "1407",
        "1408",
        "1409",
        "1410",
        "1411",
        "1412",
      ],
    };
  },
  methods: {
    toggle: function (data) {
      if (this.show_contents.indexOf(data) >= 0) {
        this.show_contents = this.show_contents.filter((n) => n !== data);
      } else {
        this.show_contents.push(data);
      }
    },

    scrollToEnd() {
      this.$nextTick(() => {
        const chatLog = document.getElementById("side-menu");
        if (!chatLog) return;
        chatLog.scrollTop = chatLog.scrollHeight;
      });
    },
  },

  updated() {
    this.scrollToEnd();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
<style lang="css" src="@/assets/css/style_select.css" scoped></style>
<style scoped>
@media screen and (max-width: 768px) {
  .bg_color {
    padding-bottom: 0;
  }
}
</style>
